import {Controller} from "@hotwired/stimulus";
import CSVBoxImporter from '@csvbox/csvboxjs';
export default class extends Controller {

    static values = {
        importer: Object
    }

    initialize() {
        console.log("initialize")
        this.importer = new CSVBoxImporter("ZuukWVGLHiX8jSymd4l7ATVXI6k1Bn",{}, function(result, data){
            console.log("callback")
            const remote_id = document.querySelector("#list_upload__id")
            remote_id.value =  data.import_id;
            let form_row_count = document.querySelector("#list_upload_upload_row_count")
            form_row_count.value = data.row_success
            //submit edit_list_upload form
            let form = document.querySelector(".edit_list_upload")
            form.submit()
        })
        console.log(this.importer)
    }

    start_upload(event) {
        console.log("start_upload")

        const list_id = document.querySelector("#list_upload_id").value
        const user_id = document.body.getAttribute('data-userid');

        this.importer.setUser({
            user_id: user_id,
            list_upload_id: list_id
        });

        this.importer.openModal();
    }


}