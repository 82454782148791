import { Controller } from "@hotwired/stimulus"
import {Cloudinary, Transformation} from "@cloudinary/url-gen"
import {sepia} from "@cloudinary/url-gen/actions/effect";
import {fillPad, limitPad, pad, scale} from "@cloudinary/url-gen/actions/resize";
import {autoGravity} from "@cloudinary/url-gen/qualifiers/gravity";
import {auto, color} from "@cloudinary/url-gen/qualifiers/background";
import { fill, crop } from "@cloudinary/url-gen/actions/resize";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { solid } from "@cloudinary/url-gen/actions/border";
import {image, text} from "@cloudinary/url-gen/qualifiers/source";
import { Position } from "@cloudinary/url-gen/qualifiers/position";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";

import { name } from "@cloudinary/url-gen/actions/namedTransformation";


import card_controller from "./card_controller";
import {set} from "@cloudinary/url-gen/actions/variable";
import {overlay} from "@cloudinary/url-gen/qualifiers/blendMode";
import {TextStyle} from "@cloudinary/url-gen/qualifiers/textStyle";
import {setString} from "@cloudinary/transformation-builder-sdk/actions/variable";

export default class extends Controller {

  static targets = ["image"];

  toggleZoom(event) {
    let activeTab = document.querySelector('.tab-pane.show.active');
    let elm = activeTab.querySelector(".image_zoom")
      elm.classList.toggle("d-none")
      this.updateImage(event)
  }

  toggleQrCode(event){
    let activeTab = document.querySelector('.tab-pane.show.active');
    let elm = activeTab.querySelector(".add_qr_code_fields")
    elm.classList.toggle("d-none")
    this.updateImage(event)
  }

  async updateQR(event){
    let activeTab = document.querySelector('.tab-pane.show.active');
    let qr_url = activeTab.querySelector(".qr_url")

        // check if the url is valid
    let url = qr_url.value.replace(" ", "")
    if (url.length > 0) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url
        qr_url.value = url
      }
      this.updateImage(event)
    }

  }

  inputImage(event) {
      console.log("inputImage")
      let publicId = event.target.value
      let activeTab = document.querySelector('.tab-pane.show.active');

      activeTab.querySelector("img").src = this.generateImageURL(publicId)  // myImage.toURL() //event.target.value
    console.log(event)
    console.log(event.target.value)
  }

  updateImage(event) {
    console.log("updateImage")
   let pane_data = {}
    const activeTab = document.querySelector('.tab-pane.show.active');

    const publicId = activeTab.querySelector(".img-input").value
    pane_data["publicId"] = publicId
    pane_data["is_full_bleed"] = activeTab.querySelector(".full_bleed").checked
    pane_data["image_zoom"] = activeTab.querySelector(".zoom").value
    pane_data["show_crop"] = activeTab.querySelector(".show_crops").checked
    pane_data["qr_url"] = ""
    pane_data["qr_cta"] = ""
    pane_data["qr"] = 0
    pane_data["ctaln"] = 0
    pane_data["ctafont"] = ""
    pane_data["text_color"] = "#FFFFFF"

    if(activeTab.querySelector(".qr_url")){
        let qr_elm = activeTab.querySelector(".qr_url")
        if(qr_elm.value.length > 0) {
            console.log(qr_elm.value)
            const domain = 'https://notedprod-0475664829e4.herokuapp.com'
            const request_url = domain + "/barcode?url=" + qr_elm.value
            console.log(request_url)
            pane_data["qr_url"] = "fetch:" + this.base64URLencode(request_url)
            console.log(pane_data["qr_url"])
            pane_data["qr"] = 1
        }else{
            pane_data["qr_url"] = ""
        }
        pane_data["qr_cta"] = activeTab.querySelector(".qr_cta").value
        pane_data["text_color"] = activeTab.querySelector(".text_color").value
        pane_data["text_color"] = pane_data["text_color"].replace("#", "rgb:")
        pane_data["ctafont"] = activeTab.querySelector(".cta_font").value + "_50_center_bold"
        pane_data["ctaln"] = pane_data["qr_cta"] ? pane_data["qr_cta"].length : 0
    }
       // if(publicId.length == 0) {
       //     console.log("No publicId");
       //     pane_data["publicId"] = "system/pxl"
       // }
      let target_image = activeTab.querySelector("img")
      target_image.src = ""
      target_image.src = this.generateImageURL(pane_data["publicId"], pane_data)
      if(publicId.length > 0) {

      }else{

      }// myImage.toURL() //event.target.value
    console.log(event)
    console.log(event.target.value)
  }

  //is_full_bleed = true, image_zoom = 1.0,  qr = 0 , qr_pk = "system:pxl", show_crop = true
  generateImageURL(publicId, pane_data) {
      console.log("generateImageURL")
      console.log(publicId)
        console.log(pane_data)


    const cld = new Cloudinary({
      cloud: {
        cloudName: 'dxyn6zcjc'
      },
      url: {
        secure: true // force https, set to false to force http
      }
    });
    const myImage = cld.image(publicId);



    myImage.addVariable(set("showcut", (pane_data.show_crop ? 1 : 0)))
    myImage.addVariable(set("fullbleed", (pane_data.is_full_bleed ? 1 : 0)))
    myImage.addVariable(set("zoom", (pane_data.image_zoom * 900)))

    myImage.namedTransformation(name("card_face"))
    if(pane_data.qr > 0){
        myImage.addVariable(set("qr", 1))
        myImage.addVariable(setString("qrlay", pane_data.qr_url))
        myImage.addVariable(set("cta", pane_data.qr_cta))
        myImage.addVariable(set("ctaln", pane_data.ctaln))
        myImage.addVariable(setString("ctafont", pane_data.ctafont))
        myImage.addVariable(setString("fcol", pane_data.text_color))
        myImage.namedTransformation(name("qr"))
    }

      myImage.resize(scale().width(833));

    return myImage.toURL();

  }
    base64URLencode(str) {
        const utf8Arr = new TextEncoder().encode(str);
        const base64Encoded = btoa(str);
        return base64Encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }


}

